import React, { useState } from 'react';
import { Box, Button, Paper, Typography, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import api from '../services/api';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ImagePreview = styled('img')({
  maxWidth: '100%',
  maxHeight: '300px',
  objectFit: 'contain',
  marginTop: '16px',
});

const CattleMuzzleMatch: React.FC = () => {
  const [image1, setImage1] = useState<File | null>(null);
  const [image2, setImage2] = useState<File | null>(null);
  const [preview1, setPreview1] = useState<string>('');
  const [preview2, setPreview2] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<{ message: string; score?: number } | null>(null);

  const handleImageChange = (imageNumber: 1 | 2) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      
      reader.onloadend = () => {
        if (imageNumber === 1) {
          setImage1(file);
          setPreview1(reader.result as string);
        } else {
          setImage2(file);
          setPreview2(reader.result as string);
        }
      };
      
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    if (!image1 || !image2) {
      alert('Please select both images');
      return;
    }

    setLoading(true);
    setResult(null);

    try {
      const formData = new FormData();
      formData.append('image1', image1);
      formData.append('image2', image2);

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await api.post('/match_two_muzzles', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      });

      setResult(response.data);
    } catch (error: any) {
      console.error('Error:', error);
      if (error.response?.status === 401) {
        setResult({ message: 'Authentication error. Please log in again.' });
      } else {
        setResult({ message: error.response?.data?.message || 'An error occurred while processing the images' });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Cattle Muzzle Match
      </Typography>
      <Typography variant="body1" gutterBottom>
        Upload two muzzle images to check if they match.
      </Typography>

      <Box sx={{ display: 'flex', gap: 3, mt: 3 }}>
        <Paper sx={{ flex: 1, p: 3 }}>
          <Typography variant="h6" gutterBottom>
            First Image
          </Typography>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Upload Image
            <VisuallyHiddenInput
              type="file"
              accept="image/*"
              onChange={handleImageChange(1)}
            />
          </Button>
          {preview1 && <ImagePreview src={preview1} alt="First muzzle" />}
        </Paper>

        <Paper sx={{ flex: 1, p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Second Image
          </Typography>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Upload Image
            <VisuallyHiddenInput
              type="file"
              accept="image/*"
              onChange={handleImageChange(2)}
            />
          </Button>
          {preview2 && <ImagePreview src={preview2} alt="Second muzzle" />}
        </Paper>
      </Box>

      <Box sx={{ mt: 3, textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!image1 || !image2 || loading}
          sx={{ minWidth: 200 }}
        >
          {loading ? <CircularProgress size={24} /> : 'Compare Images'}
        </Button>
      </Box>

      {result && (
        <Paper sx={{ mt: 3, p: 3, textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>
            Result
          </Typography>
          <Typography>
            {result.message}
            {result.score !== undefined && ` (Score: ${(result.score * 100).toFixed(2)}%)`}
          </Typography>
        </Paper>
      )}
    </Box>
  );
};

export default CattleMuzzleMatch;
