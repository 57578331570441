import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { store } from './store/store';
import Login from './pages/Login';
import AppLayout from './components/Layout/AppLayout';
import MuzzleDetection from './pages/MuzzleDetection';
import Dashboard from './pages/Dashboard';
import CattleMuzzleMatch from './pages/CattleMuzzleMatch';
import MuzzleMatching from './pages/MuzzleMatching';
import Statistics from './pages/Statistics';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

// Protected Route component
const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
};

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <Dashboard />
                  </AppLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/muzzle-detection"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <MuzzleDetection />
                  </AppLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/cattle-muzzle-match"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <CattleMuzzleMatch />
                  </AppLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/muzzle-matching"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <MuzzleMatching />
                  </AppLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/statistics"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <Statistics />
                  </AppLayout>
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
