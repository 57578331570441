import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import api from '../services/api';

const Input = styled('input')({
  display: 'none',
});

interface DetectionResult {
  message: string;
  muzzle_image?: string;
  error?: string;
}

const MuzzleDetection: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [result, setResult] = useState<DetectionResult | null>(null);
  const [loading, setLoading] = useState(false);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      setResult(null);
    }
  };

  const handleSubmit = async () => {
    if (!selectedFile) return;

    setLoading(true);
    const formData = new FormData();
    formData.append('image', selectedFile);

    try {
      const response = await api.post<DetectionResult>('/detect_muzzle', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setResult(response.data);
    } catch (error: any) {
      console.error('Error detecting muzzle:', error);
      setResult({
        error: error.response?.data?.error || 'An error occurred while processing the image',
        message: 'Detection failed'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Muzzle Detection
      </Typography>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                Upload Image
              </Typography>
              <label htmlFor="muzzle-image">
                <Input
                  accept="image/*"
                  id="muzzle-image"
                  type="file"
                  onChange={handleFileSelect}
                />
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                >
                  Select Image
                </Button>
              </label>
              {previewUrl && (
                <Card sx={{ mt: 2 }}>
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom>
                      Original Image
                    </Typography>
                  </CardContent>
                  <CardMedia
                    component="img"
                    image={previewUrl}
                    alt="Selected image"
                    sx={{ maxHeight: 300, objectFit: 'contain' }}
                  />
                </Card>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              result && (
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Detection Results
                  </Typography>
                  
                  {result.error ? (
                    <Alert severity="error" sx={{ mb: 2 }}>
                      {result.error}
                    </Alert>
                  ) : (
                    <>
                      <Alert severity="success" sx={{ mb: 2 }}>
                        {result.message}
                      </Alert>

                      {result.muzzle_image && (
                        <Card>
                          <CardContent>
                            <Typography variant="subtitle1" gutterBottom>
                              Detected Muzzle
                            </Typography>
                          </CardContent>
                          <CardMedia
                            component="img"
                            image={`data:image/jpeg;base64,${result.muzzle_image}`}
                            alt="Detected muzzle"
                            sx={{ maxHeight: 300, objectFit: 'contain' }}
                          />
                        </Card>
                      )}
                    </>
                  )}
                </Box>
              )
            )}
          </Grid>
        </Grid>
        <Box sx={{ mt: 3, textAlign: 'center' }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!selectedFile || loading}
            size="large"
          >
            {loading ? 'Processing...' : 'Detect Muzzle'}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default MuzzleDetection;
