import React from 'react';
import { Box, Grid, Paper, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CompareIcon from '@mui/icons-material/Compare';
import BarChartIcon from '@mui/icons-material/BarChart';

interface FeatureCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  path: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, description, icon, path }) => {
  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        p: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        transition: 'transform 0.2s',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: 3,
        },
      }}
    >
      <Box sx={{ mb: 2, color: 'primary.main' }}>{icon}</Box>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2, flexGrow: 1 }}>
        {description}
      </Typography>
      <Button variant="contained" onClick={() => navigate(path)}>
        Get Started
      </Button>
    </Paper>
  );
};

const Dashboard: React.FC = () => {
  const features = [
    {
      title: 'Muzzle Detection',
      description: 'Upload images to detect and analyze cattle muzzle patterns with advanced AI.',
      icon: <PhotoCameraIcon sx={{ fontSize: 40 }} />,
      path: '/muzzle-detection',
    },
    {
      title: 'Cattle Tagging',
      description: 'Tag and organize your cattle database with detailed information and images.',
      icon: <PhotoCameraIcon sx={{ fontSize: 40 }} />,
      path: '/cattle-tagging',
    },
    {
      title: 'Muzzle Matching',
      description: 'Compare and match muzzle patterns to identify individual cattle.',
      icon: <CompareIcon sx={{ fontSize: 40 }} />,
      path: '/muzzle-matching',
    },
    {
      title: 'Statistics',
      description: 'View detailed analytics and insights about your cattle management system.',
      icon: <BarChartIcon sx={{ fontSize: 40 }} />,
      path: '/statistics',
    },
  ];

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Welcome to Muzzle Matcher
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        Your comprehensive solution for cattle identification and management using AI-powered muzzle pattern recognition.
      </Typography>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {features.map((feature) => (
          <Grid item xs={12} sm={6} md={3} key={feature.title}>
            <FeatureCard {...feature} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Dashboard;
